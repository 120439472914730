import React, { useEffect, useState } from "react";
import axios from "axios";

const App = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [data, setData] = useState([]);
  const [notification, setNotification] = useState({ type: "", message: "" });
  const [storeInputs, setStoreInputs] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const backendURL = "https://dashboard.mimithemes.com";

  // Styles object
  const styles = {
    container: {
      minHeight: "100vh",
      backgroundColor: "#f5f5f5",
      padding: "20px",
      fontFamily: "Arial, sans-serif",
    },
    card: {
      maxWidth: "1000px",
      margin: "0 auto",
      backgroundColor: "white",
      borderRadius: "8px",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      padding: "20px",
    },
    header: {
      borderBottom: "1px solid #eee",
      marginBottom: "20px",
      paddingBottom: "20px",
    },
    title: {
      fontSize: "24px",
      fontWeight: "bold",
      margin: "0",
      color: "#333",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      gap: "15px",
    },
    input: {
      padding: "10px",
      border: "1px solid #ddd",
      borderRadius: "4px",
      fontSize: "16px",
    },
    button: {
      padding: "10px 20px",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      fontSize: "16px",
      fontWeight: "500",
      transition: "background-color 0.2s",
    },
    primaryButton: {
      backgroundColor: "#007bff",
      color: "white",
    },
    dangerButton: {
      backgroundColor: "#dc3545",
      color: "white",
    },
    notification: {
      padding: "15px",
      borderRadius: "4px",
      marginBottom: "20px",
    },
    successNotification: {
      backgroundColor: "#d4edda",
      color: "#155724",
      border: "1px solid #c3e6cb",
    },
    errorNotification: {
      backgroundColor: "#f8d7da",
      color: "#721c24",
      border: "1px solid #f5c6cb",
    },
    productGrid: {
      display: "grid",
      gap: "20px",
      marginTop: "20px",
    },
    productCard: {
      border: "1px solid #eee",
      borderRadius: "4px",
      padding: "20px",
      backgroundColor: "white",
    },
    productHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      marginBottom: "15px",
    },
    productTitle: {
      fontSize: "18px",
      fontWeight: "bold",
      margin: "0 0 5px 0",
    },
    productMeta: {
      fontSize: "14px",
      color: "#666",
      margin: "0 0 5px 0",
    },
    inputGroup: {
      display: "flex",
      gap: "10px",
    },
    flexBetween: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  };

  const showNotification = (type, message) => {
    setNotification({ type, message });
    setTimeout(() => setNotification({ type: "", message: "" }), 5000);
  };

  const handleVerifyToken = async (savedToken) => {
    try {
      const response = await axios.post(
        `${backendURL}/verify-token`,
        {},
        {
          headers: {
            Authorization: `Bearer ${savedToken || token}`,
          },
        }
      );
      showNotification("success", `Welcome back, ${response.data.user.email}!`);
      setToken(savedToken || token);
      getdata(savedToken || token);
    } catch (error) {
      setToken("");
      localStorage.removeItem("authToken");
      showNotification("error", error.response?.data?.error || "Session expired. Please login again.");
    }
  };

  useEffect(() => {
    const savedToken = localStorage.getItem("authToken");
    if (savedToken) {
      handleVerifyToken(savedToken);
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(`${backendURL}/login`, {
        email,
        password,
      });
      const newToken = response.data.token;
      setToken(newToken);
      localStorage.setItem("authToken", newToken);
      showNotification("success", "Successfully logged in!");
      getdata(newToken);
    } catch (error) {
      showNotification("error", error.response?.data?.error || "Invalid credentials");
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = () => {
    setToken("");
    localStorage.removeItem("authToken");
    showNotification("success", "Successfully logged out");
    setData([]);
  };

  const getdata = async (savedToken) => {
    try {
      const response = await axios.post(
        `${backendURL}/getdata`,
        {},
        {
          headers: {
            Authorization: `Bearer ${savedToken || token}`,
          },
        }
      );
      setData(response.data.data);
    } catch (error) {
      showNotification("error", "Failed to fetch data");
      setData([]);
    }
  };

  const updatestore = async (store, id) => {
    try {
      await axios.post(
        `${backendURL}/updatestore`,
        {
          store: store,
          id: id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      showNotification("success", "Store updated successfully");
      getdata(token);
    } catch (error) {
      showNotification("error", "Failed to update store");
    }
  };

  const handleInputChange = (id, value) => {
    setStoreInputs((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleSendData = (id) => {
    const storeValue = storeInputs[id];
    updatestore(storeValue, id);
  };

  const NotificationComponent = ({ type, message }) => {
    if (!message) return null;
    
    const notificationStyle = {
      ...styles.notification,
      ...(type === "success" ? styles.successNotification : styles.errorNotification),
    };
    
    return (
      <div style={notificationStyle}>
        {message}
      </div>
    );
  };

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <div style={styles.header}>
          <h1 style={styles.title}>Dashboard</h1>
        </div>

        <NotificationComponent type={notification.type} message={notification.message} />

        {!token ? (
          <form onSubmit={handleLogin} style={styles.form}>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={styles.input}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              style={styles.input}
            />
            <button
              type="submit"
              disabled={isLoading}
              style={{ ...styles.button, ...styles.primaryButton }}
            >
              {isLoading ? "Logging in..." : "Login"}
            </button>
          </form>
        ) : (
          <div>
            <div style={styles.flexBetween}>
              <h2 style={{ ...styles.title, fontSize: "20px" }}>Products</h2>
              <button
                onClick={handleLogout}
                style={{ ...styles.button, ...styles.dangerButton }}
              >
                Logout
              </button>
            </div>
            
            <div style={styles.productGrid}>
              {data?.products?.map((product) => (
                <div key={product.id} style={styles.productCard}>
                  <div style={styles.productHeader}>
                    <div>
                      <h3 style={styles.productTitle}>
                        {product.title || 'Untitled'}
                      </h3>
                      <p style={styles.productMeta}>
                        Type: {product.type || 'N/A'}
                      </p>
                      <p style={styles.productMeta}>
                        Token: {product.unique_key || 'N/A'}
                      </p>
                    </div>
                    {product.store && (
                      <button
                        onClick={() => window.open(product.link, '_blank')}
                        style={{ ...styles.button, ...styles.primaryButton }}
                      >
                        View
                      </button>
                    )}
                  </div>
                  <div style={styles.inputGroup}>
                    <input
                      style={{ ...styles.input, flex: 1 }}
                      placeholder="Enter store"
                      value={storeInputs[product.id] || product.store || ''}
                      onChange={(e) => handleInputChange(product.id, e.target.value)}
                    />
                    <button
                      onClick={() => handleSendData(product.id)}
                      style={{ ...styles.button, ...styles.primaryButton }}
                    >
                      Update
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default App;